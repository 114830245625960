<template>
  <div>

    <hb-form label="API Key" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.api_key"
        v-validate.disable="'required'"
        data-vv-name="api_key"
        data-vv-as="Api Key"
        :error="errors.collect('api_key').length > 0"
      />
    </hb-form> 

    <hb-form label="Acct Number" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.account_number"
        v-validate.disable="'required'"
        data-vv-name="account_number"
        data-vv-as="Account Number"
        :error="errors.collect('account_number').length > 0"
      />
    </hb-form> 



    <hb-form label="Device ID" required>
      <HbTextField
        :disabled="!!form.id"
        v-model="form.value.deviceId"
        v-validate.disable="'required'"
        data-vv-name="deviceId"
        data-vv-as="Device ID"
        :error="errors.collect('deviceId').length > 0"
      />
    </hb-form> 

      <div style="display: flex" >
          <v-col cols="8" class="hb-default-font-size font-weight-medium">Swiper Terminals</v-col>
          <v-col cols="4" class="align-right">
            <a v-if="!form.id || isEditing" @click="addDevice()">+ Add New Device</a> 
            
            </v-col>
      </div>

      <v-divider></v-divider>
      <v-expansion-panels flat v-model="devicePanel" multiple class="hb-expansion-panel ma-0 pa-0">

        <!-- <hb-expansion-panel class="ma-0 pa-0" v-for="(d, i) in form.value.Devices" :key="i"> -->
        <v-expansion-panel class="ma-0 pa-0" v-for="(d, i) in form.Devices" :key="i">
          <v-expansion-panel-header class="hb-table-label py-0 my-0 px-4 hb-subpanel-header">
              <v-col cols="8" class="pa-0 ma-0">
                  <span class="hb-default-font-size font-weight-medium">Device {{i+1}}</span>
              </v-col>
               <template v-slot:actions >
                  <div class="d-flex align-center mr-3">
                      <hb-link v-if="!form.id || isEditing" @click="removeDevice(i)" class="hb-default-font-size">Remove</hb-link>
                      <template v-else>
					  
                      <hb-link small  @click="testDevice(i)" class="hb-default-font-size">Test Credit Card Reader</hb-link>
                      <loader v-show="testDeviceLoading === i" color="#00b2ce" size="20px" class="inline-loader"></loader>
                      </template>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon>$expand</v-icon>
                  </div>
              </template> 
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
            <hb-form label="Device Name">
              <HbTextField
                :disabled="!!form.id && !isEditing"
                v-model="form.Devices[i].name"
                v-validate="'required|max:100'"
                data-vv-name="device_name"
                data-vv-as="Device Name"
                :error="errors.collect('device_name').length > 0"
                placeholder="Enter Device Name"
              />
            </hb-form> 


            <hb-form label="Device IP Address">
              <div v-show="!!form.id && !isEditing" class="checkmark" >
              <span class="icon"></span><span v-if ="form.Devices[i].lan==1"> Local Network </span><span v-if ="form.Devices[i].lan==0"> Internet </span>
                
              </div>
              <v-row v-show ="(form.id && isEditing) || !form.id ">

              <hb-radio-group   class="mt-1" v-model="form.Devices[i].lan" :mandatory="true" row>
                <div disable="true">Select the network communication type then enter the IP address.</div>
                <hb-radio value="0">
                  <template v-slot:label>
                Internet
              </template>
                </hb-radio>
                  <hb-radio value="1">
                  <template v-slot:label>
                Local Network
        </template>
                </hb-radio>
                
              </hb-radio-group>
            </v-row>

              <HbTextField
                :disabled="!!form.id && !isEditing"
                v-model="form.Devices[i].ip"
                v-validate="{ required: true, regex: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/ ||  /^((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/}"
                data-vv-name="ip"
                data-vv-as="Device IP"
                :error="errors.collect('ip').length > 0"
                placeholder="Enter Device IP Address"
              />

              <HbTextField
                v-if="form.Devices[i].lan == 1"
                :disabled="true"
                :v-model="form.Devices[i].hostname ? form.Devices[i].hostname : form.Devices[i].hostname = 'device'+ (i+1) +'.pax.us'"
                data-vv-name="ip"
                data-vv-as="Device IP"
                :error="errors.collect('ip').length > 0"
                :placeholder="form.Devices[i].hostname"
              />
              
            </hb-form> 



            <hb-form label="Device Port">
                <HbTextField
                  :disabled="!!form.id && !isEditing"
                  v-model="form.Devices[i].port"
                  v-validate="{ required: true, regex: /^(?:[0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-6])$/}"
                  data-vv-name="device_port"
                  data-vv-as="Device Port"
                  :error="errors.collect('device_port').length > 0"
                  placeholder="Enter Device Port"
                />
              </hb-form> 


               <hb-form label="Device ID">
                  <HbTextField
                    :disabled="!!form.id && !isEditing"
                    v-model="form.Devices[i].identifier"
                    v-validate="'required|max:100'"
                    data-vv-name="identifier"
                    :error="errors.collect('identifier').length > 0"
                    placeholder="Enter Device ID"
                  />

                 <v-row no-gutters class="" v-if="form.Devices[i].lan == 1 && form.Devices[i].ip && form.Devices[i].port && form.Devices[i].identifier"><span class="pt-2">Download Setup </span>  <HbBtn  @click="configueModel = 1" icon tooltip="Download" mdi-code="mdi-download" /></v-row>

              </hb-form>
              
              <hb-modal
      v-model="configueModel"
      v-if="configueModel"
      size="medium"
      title=" Configuring Tenant Payments"
      show-help-link
      > 
        <template v-slot:content>
          <div class="py-4 px-6">
            Click on to Download Terminal Installer download the zipped installer file in order to setup connection with the PAX terminal.
            Necessary Subsequent Steps for setting up (Important):            <br /><br />
            <ul>
              <li>Go to Downloads (or wherever your browser saves files)</li>
              <li>Locate and unzip the installer-device-"dn” (dn refers to your device number)</li>
              <li>Open the Readme file and adhere to the further instructions.</li>

            </ul>
          </div>
        </template>
        <template v-slot:actions>
            <hb-btn @click="downloadPaxFile(i)">
              Download Terminal Installer
          </hb-btn>
        </template>
      </hb-modal>
              
          </v-expansion-panel-content> 
        </v-expansion-panel>
      </v-expansion-panels>
 

    


    <hb-bottom-action-bar :cancel-off="(!!form.id && !isEditing) || !isEditAble" @close="cancel"  v-if="hasPermission('manage_payment_gateways')" :top-border="false">
      <template v-slot:right-actions > 
          <hb-btn :disabled="!hasPermission('delete_payment_gateway')" @click="remove" color="destructive" v-if="form.id && !isEditing && isEditAble">Remove Integration</hb-btn>
          <hb-btn @click="edit" v-if="form.id && !isEditing && isEditAble">Edit</hb-btn>
          <hb-btn @click="save" v-if="(!form.id || isEditing) && isEditAble">Save</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div> 
</template>
<script>

import BaseSettings from '../../BasePaymentSettingsForm.vue' ;
import api from '../../../../../assets/api.js'
import moment from 'moment';

export default {
  name: 'TenantPaymentsCard',
  extends: BaseSettings,
  data() {
    return {
      devicePanel: [],
      configueModel: false,
      defaultValues: {
        testing_device: null,
        api_key: '',
        // device_id: '',
        
        account_number: ''

      }
    }
  },
  props: ['connection'],
  methods: {
    async downloadPaxFile(i) {


this.configueModel = false
this.form.Devices[i].count=i
let response = await this.$http.post(api.PROPERTIES + this.property_id + '/connections/download', this.form.Devices[i], { responseType: 'blob' });
                    let blob = new Blob([response.data], {
                        type: response.headers.get("content-type")
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `installer-device${i+1}${moment().utc().local().format('HH:mm:ss')}.zip`;
                    link.click();
                    delete this.form.Devices[i].count
}

  },
}
</script>

