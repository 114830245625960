<template>
  <div>

    <hb-form label="Direct Deposit" required>
      <HbSwitch
        class="ml-6"
        v-model="form.value.directdeposit"
        :label="form.value.directdeposit ? 'Enabled' : 'Disabled'"
         @change='save'/>
    </hb-form>
  </div>
</template>

<script>
  import BaseSettings from '../../BasePaymentSettingsForm.vue'
  export default {
    name: 'FatzebraDebitSettings',
    extends: BaseSettings, 
    data() {
      return {

        defaultValues: {
          directdeposit: false
        }        
      }
    },
    methods:{
    handleDirectDeposit(value) {
      if (value) {
        this.form.value.directdeposit = true
        this.$emit("save", true);
      }
      else {
        this.$emit("remove");
      }
    },
    }
  }
</script>
