<template>
    <v-expansion-panels v-model="panel">
    <hb-expansion-panel class="mb-2">
        <template v-slot:title>
            Direct Deposit
        </template>
        <template v-slot:content>
            <hb-form label="Direct Deposit" required>
            <HbSwitch
                class="ml-6"
                v-model="form.value.directdeposit"
                :label="form.value.directdeposit ? 'Enabled' : 'Disabled'"
                @change='handleDirectDeposit'/>
            </hb-form>


        </template>
    
        </hb-expansion-panel>     

    </v-expansion-panels>  
</template>

<script type="text/babel">

    import DirectDeposit from './merchants/DirectDeposit.vue'

    import { mapGetters } from 'vuex';
    export default {
        name: 'PaymentConfigurationDirectDebit',
        data() {
            return {
                panel: 0,
                
                form: {
                    id: '',
                    name: 'directdeposit',
                    property_id: '', 
                    type: 'directdeposit',
                    value: {}
                }
            }
        },
        props: ['connection', 'merchants', 'property_id'], 
        components: {
            DirectDeposit
        },
        created(){  
            
        },
        computed:{
             ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission'
            }), 
        },
        methods:{
            async save(selected){
                let form = Object.assign(this.form, selected);
                await this.$emit('save', form);
            }, 
            async remove(selected){
                await this.$emit('remove', selected);
            },
            cancel(){
                this.form = JSON.parse(JSON.stringify(this.connection));
            },
            async handleDirectDeposit(value) {
                if (this.form.value.directdeposit) {
                    this.form.name='directdeposit'
                    await this.save(this.form)
                }
                else {
                    await this.remove(this.form)
                }
                },
        },
        watch: {
            connection: { 
                handler: function (val, oldVal) {
                     this.form = JSON.parse(JSON.stringify(val));
            
                },
                deep: true

            }

        }
    }

</script>

